import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { SocialMediaFacade } from '../facades/social-media.facade';

export const socialMediaCleanup: CanDeactivateFn<unknown> = () => {
    const socialMediaFacade = inject(SocialMediaFacade);

    socialMediaFacade.clear();
    return true;
};
