import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SOCIAL_MEDIA_FEATURE_KEY } from './keys';
import { socialMediaReducer } from './reducers/social-media.reducer';
import { SocialMediaEffects } from './effects/social-media.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(SOCIAL_MEDIA_FEATURE_KEY, socialMediaReducer), EffectsModule.forFeature([SocialMediaEffects])],
})
export class SocialMediaStoreModule {}
