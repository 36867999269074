import { PlatformName, platformNames } from '../types/platform-name.type';
import { PlatformsStatusDto } from './platforms-status.dto';

interface PlatformStatus {
    key: PlatformName;
    connected: boolean;
    available: boolean;
    affectsChart: boolean;
    readonly: boolean;
}

const affectsChart = (key: PlatformName) => {
    switch (key) {
        case 'youtube':
        case 'spotify':
        case 'tiktok':
        case 'instagram':
        case 'facebook':
            return true;

        default:
            return false;
    }
};

const parsePlatformsStatus = (dto: PlatformsStatusDto) =>
    (Object.keys(dto) as PlatformName[])
        .map((key): PlatformStatus => ({ key, ...dto[key], affectsChart: affectsChart(key) }))
        .sort((a, b) => platformNames.indexOf(a.key) - platformNames.indexOf(b.key));

export { parsePlatformsStatus, PlatformStatus };
