import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SocialMediaState } from '../reducers/social-media.reducer';
import { SOCIAL_MEDIA_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<SocialMediaState>(SOCIAL_MEDIA_FEATURE_KEY);

export const selectAvailablePlatforms = createSelector(selectState, (state) => state.platformsStatus?.filter((value) => value.available));
export const selectChartAffectingPlatformsConnected = createSelector(selectState, (state) =>
    Boolean(state.platformsStatus?.filter((value) => value.affectsChart && value.available && value.connected).length),
);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);

export const selectRedirectToProfile = createSelector(selectState, (state) => state.redirectToProfile);
