import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PlatformName } from '../types';
import { PlatformStatus } from '../models';

export const socialMediaActions = createActionGroup({
    source: 'Social Media',
    events: {
        'Get Platforms Status': emptyProps(),
        'Get Platforms Status Success': props<{ platformsStatus: PlatformStatus[] }>(),
        'Get Platforms Status Error': emptyProps(),

        'Connect Platform': props<{ platform: PlatformName }>(),
        'Connect Platform Success': props<{ authUrl: string }>(),
        'Connect Platform Error': emptyProps(),

        'Disconnect Platform': props<{ platform: PlatformName }>(),
        'Disconnect Platform Success': props<{ platform: PlatformName }>(),
        'Disconnect Platform Error': emptyProps(),

        'Login To Platform': props<{ platform: PlatformName; code: string }>(),
        'Login To Platform Success': emptyProps(),
        'Login To Platform Error': emptyProps(),

        'Clear Redirect To Profile': emptyProps(),
        Clear: emptyProps(),
    },
});
