import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, switchMap, tap, fromEvent, filter, finalize } from 'rxjs';
import { LoadersFacade } from '@app/store/loader';
import { socialMediaActions } from '../actions/social-media.actions';
import { HttpService } from '../services/http.service';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SocialMediaEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loadersFacade = inject(LoadersFacade);
    private readonly messageService = inject(MessageService);
    private readonly translateService = inject(TranslateService);

    refreshPlatformStatus$ = createEffect(() => {
        return fromEvent<StorageEvent>(window, 'storage').pipe(
            filter((event) => event.key === 'refresh-socials' && event.newValue === 'true'),
            map(() => socialMediaActions.getPlatformsStatus()),
        );
    });

    getPlatformsStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(socialMediaActions.getPlatformsStatus, socialMediaActions.loginToPlatformSuccess),
            switchMap(() => {
                this.loadersFacade.add('get-platforms-status');
                return this.httpService.getPlatformsStatus().pipe(
                    map((platformsStatus) => socialMediaActions.getPlatformsStatusSuccess({ platformsStatus })),
                    catchError(() => of(socialMediaActions.getPlatformsStatusError())),
                    finalize(() => this.loadersFacade.delete('get-platforms-status')),
                );
            }),
        );
    });

    connectPlatform$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(socialMediaActions.connectPlatform),
            switchMap(({ platform }) => {
                return this.httpService.connectPlatform(platform).pipe(
                    map(({ authUrl }) => socialMediaActions.connectPlatformSuccess({ authUrl })),
                    catchError(() => of(socialMediaActions.connectPlatformError())),
                );
            }),
        );
    });

    connectPlatformSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(socialMediaActions.connectPlatformSuccess),
                tap(({ authUrl }) => {
                    window.open(authUrl);
                }),
            );
        },
        { dispatch: false },
    );

    disconnectPlatform$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(socialMediaActions.disconnectPlatform),
            switchMap(({ platform }) => {
                return this.httpService.disconnectPlatform(platform).pipe(
                    map(() => socialMediaActions.disconnectPlatformSuccess({ platform })),
                    catchError(() => of(socialMediaActions.disconnectPlatformError())),
                );
            }),
        );
    });

    loginToPlatform$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(socialMediaActions.loginToPlatform),
            switchMap(({ platform, code }) => {
                this.loadersFacade.add('login-to-platform');
                return this.httpService.loginToPlatform(platform, code).pipe(
                    map(() => socialMediaActions.loginToPlatformSuccess()),
                    catchError(() => of(socialMediaActions.loginToPlatformError())),
                );
            }),
        );
    });

    loginToPlatformSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(socialMediaActions.loginToPlatformSuccess),
                tap(() => {
                    this.loadersFacade.delete('login-to-platform');
                    this.messageService.add(this.translateService.instant('cl.account.account-link-success'), 'success');
                }),
            );
        },
        { dispatch: false },
    );

    loginToPlatformError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(socialMediaActions.loginToPlatformError),
                tap(() => {
                    this.loadersFacade.delete('login-to-platform');
                    this.messageService.add(this.translateService.instant('cl.account.account-link-error'), 'danger');
                }),
            );
        },
        { dispatch: false },
    );

    clearRedirectTrigger$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(socialMediaActions.loginToPlatformSuccess, socialMediaActions.loginToPlatformError),
            map(() => socialMediaActions.clearRedirectToProfile()),
        );
    });
}
