import { createReducer, on } from '@ngrx/store';
import { socialMediaActions } from '../actions/social-media.actions';
import { PlatformStatus } from '../models';

export interface SocialMediaState {
    platformsStatus: PlatformStatus[] | null;
    loaded: null | boolean;
    redirectToProfile: boolean | null;
}

export const initialState: SocialMediaState = {
    platformsStatus: null,
    loaded: null,
    redirectToProfile: null,
};

export const socialMediaReducer = createReducer(
    initialState,

    on(socialMediaActions.getPlatformsStatus, (state): SocialMediaState => ({ ...state, loaded: null })),
    on(socialMediaActions.getPlatformsStatusSuccess, (state, { platformsStatus }): SocialMediaState => ({ ...state, platformsStatus, loaded: true })),
    on(socialMediaActions.getPlatformsStatusError, (state): SocialMediaState => ({ ...state, loaded: false })),

    on(socialMediaActions.disconnectPlatformSuccess, (state, { platform }): SocialMediaState => {
        if (state.platformsStatus === null) {
            return state;
        }

        const platformsStatus: PlatformStatus[] = state.platformsStatus.map((status) => (status.key === platform ? { ...status, connected: false } : status));

        return { ...state, platformsStatus };
    }),

    on(socialMediaActions.loginToPlatform, (state): SocialMediaState => ({ ...state, loaded: null, redirectToProfile: null })),
    on(socialMediaActions.loginToPlatformSuccess, (state): SocialMediaState => ({ ...state, loaded: true, redirectToProfile: true })),
    on(socialMediaActions.loginToPlatformError, (state): SocialMediaState => ({ ...state, loaded: true, redirectToProfile: true })),

    on(socialMediaActions.clearRedirectToProfile, (state): SocialMediaState => ({ ...state, redirectToProfile: null })),

    on(socialMediaActions.clear, (): SocialMediaState => ({ ...initialState })),
);
