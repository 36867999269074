import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { socialMediaActions } from '../actions/social-media.actions';
import { selectAvailablePlatforms, selectChartAffectingPlatformsConnected, selectLoaded, selectRedirectToProfile } from '../selectors/social-media.selector';
import { PlatformStatus } from '../models';
import { PlatformName } from '../types';

@Injectable({
    providedIn: 'root',
})
export class SocialMediaFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    chartAffectingPlatformsConnected$ = this.store.select(selectChartAffectingPlatformsConnected);
    availablePlatforms$ = this.store
        .select(selectAvailablePlatforms)
        .pipe(filter((availablePlatforms): availablePlatforms is PlatformStatus[] => availablePlatforms !== undefined));
    readonly redirectToProfile$ = this.store.select(selectRedirectToProfile);

    getPlatformsStatus() {
        this.store.dispatch(socialMediaActions.getPlatformsStatus());
    }

    connectPlatform(platform: PlatformName) {
        this.store.dispatch(socialMediaActions.connectPlatform({ platform }));
    }

    disconnectPlatform(platform: PlatformName) {
        this.store.dispatch(socialMediaActions.disconnectPlatform({ platform }));
    }

    clear() {
        this.store.dispatch(socialMediaActions.clear());
    }

    loginToPlatform(platform: PlatformName, code: string) {
        this.store.dispatch(socialMediaActions.loginToPlatform({ platform, code }));
    }
}
