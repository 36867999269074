import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { parsePlatformsStatus, PlatformsStatusDto } from '../models';
import { PlatformName } from '../types';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getPlatformsStatus() {
        return this.httpClient.get<ApiResponse<PlatformsStatusDto>>('api/v1/connect').pipe(map((res) => parsePlatformsStatus(res.data)));
    }

    connectPlatform(platform: PlatformName) {
        const url = this.getConnectPlatformUrl(platform);

        /* Local redirect: { params: { redirectToLocal: true } } **/
        return this.httpClient.get<{ authUrl: string }>(url);
    }

    disconnectPlatform(platform: PlatformName) {
        const url = this.getDisconnectPlatformUrl(platform);

        return this.httpClient.delete(url);
    }
    loginToPlatform(platform: PlatformName, code: string) {
        const url = this.getLoginToPlatformUrl(platform);
        return this.httpClient.post(url, { code });
    }
    private getConnectPlatformUrl(platform: PlatformName) {
        switch (platform) {
            case 'youtube':
                return 'api/v1/connect/youtube/auth_url';
            case 'tiktok':
                return 'api/v1/connect/tiktok/auth_url';
            case 'instagram':
                return 'api/v1/connect/instagram/auth_url';
            case 'twitter':
            case 'facebook':
            case 'googleCalendar':
            case 'googleMail':
            case 'spotify':
                return '';
        }
    }

    private getDisconnectPlatformUrl(platform: PlatformName) {
        switch (platform) {
            case 'youtube':
                return 'api/v1/connect/youtube/disconnect';
            case 'tiktok':
                return 'api/v1/connect/tiktok/disconnect';
            case 'instagram':
                return 'api/v1/connect/instagram/disconnect';
            case 'twitter':
            case 'facebook':
            case 'googleCalendar':
            case 'googleMail':
            case 'spotify':
                return '';
        }
    }

    private getLoginToPlatformUrl(platform: PlatformName) {
        switch (platform) {
            case 'tiktok':
                return 'api/v1/connect/tiktok/login';
            case 'youtube':
                return 'api/v1/connect/youtube/login';
            case 'instagram':
                return 'api/v1/connect/instagram/login';
            case 'facebook':
            case 'googleCalendar':
            case 'googleMail':
            case 'spotify':
            case 'twitter':
                return '';
        }
    }
}
