import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SocialMediaFacade } from '../facades/social-media.facade';
import { filter, map, take, tap } from 'rxjs';

export const socialMediaStatusLoaded: CanActivateFn = () => {
    const socialMediaFacade = inject(SocialMediaFacade);

    return socialMediaFacade.loaded$.pipe(
        tap((loaded) => {
            if (loaded === null) {
                socialMediaFacade.getPlatformsStatus();
            }
        }),
        filter((loaded) => loaded !== null),
        take(1),
        map(() => true),
    );
};
